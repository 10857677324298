<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
            <div>
              <div style="font-size: 1.4em; color: inherit">{{ $t('Order') }}: {{ $route.params.number ?? '' }}</div>
              <div v-if="orderData?.parentOffer?.number" class="p-mt-1">
                <span class="p-mr-1">From offer:</span>
                <router-link :to="{ path: '/offers', query: { search: orderData.parentOffer.number } }" target="_blank">
                  <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>
                  <span>{{ orderData.parentOffer.number }}</span>
                </router-link>
              </div>
              <div v-else-if="orderData?.parentOrder?.number" class="p-mt-1">
                <span class="p-mr-1">From order:</span>
                <router-link :to="{ path: `/orders/${orderData.parentOrder.number}` }" target="_blank">
                  <i style="font-size: 0.90em" class="ti-hummer"></i>
                  <span>{{ orderData.parentOrder.number }}</span>
                </router-link>
              </div>
            </div>
          </template>
<!--          <template v-slot:right v-if="orderData">-->
          <template #end v-if="orderData">
            <div class="p-d-flex p-flex-wrap p-ai-center">
              <div class="p-mr-2">
                <router-link class="p-d-flex p-ai-center p-jc-center p-p-2"
                             v-if="orderData.childOffer?.number"
                             :to="{ path: '/offers', query: { search: orderData.childOffer.number } }"
                             target="_blank">
                  <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>
                  <div>{{ orderData.childOffer.number }}</div>
                </router-link>
                <AddNewButton v-else :disabled="disableEditButton || !allDataLoaded || dataIsSending" :label="$t('Create offer')" @click="addNewOffer"/>
              </div>
              <div class="p-mr-2">
                <router-link class="p-d-flex p-ai-center p-jc-center p-p-2"
                             v-if="orderData.childOrder?.number"
                             :to="{ path: `/orders/${orderData.childOrder.number}` }"
                             target="_blank">
                  <i style="font-size: 0.90em" class="ti-hummer p-mr-1"></i>
                  <div>{{ orderData.childOrder.number }}</div>
                </router-link>
                <AddNewButton v-else :disabled="disableEditButton || !allDataLoaded || dataIsSending" :label="$t('Dublicate')" @click="dublicateOrder"/>
              </div>
<!--              <div class="p-mr-2">-->
<!--                <div v-if="orderData.childOrder">Order created: {{ orderData.childOrder.number}}</div>-->
<!--                <AddNewButton v-else :disabled="disableEditButton || !allDataLoaded || dataIsSending" label="Dublicate order" @click="dublicateOrder"/>-->
<!--              </div>-->

<!--              <Button :disabled="!allDataLoaded || dataIsSending" label="Create offer" icon="ti-plus" class="p-button-secondary p-button-raised p-mr-2"/>-->
              <Button :disabled="disableEditButton || !allDataLoaded || dataIsSending" v-if="showEditOrderButton" :label="$t('Edit')" @click="editOrder" icon="ti-pencil" class="p-button-success p-button-raised p-mr-2"/>
<!--              <Button v-if="orderData.state !== 9 && orderData.state !== 10 && orderData.state !== 12 || dataIsSending" label="Cancel order" @click="confirmCancelOrder" icon="ti-close" class="p-button-danger p-button-raised p-mr-2"/>-->
<!--              <Button :disabled="thereIsActiveOrderedRequestPositions" v-if="!orderData.is_invoiced && (orderData.state === 1 || orderData.state === 3 || orderData.state === 5) || dataIsSending" label="Cancel order" @click="confirmCancelOrder" icon="ti-close" class="p-button-danger p-button-raised p-mr-2"/>-->
              <Button :disabled="disableEditButton || !allDataLoaded || dataIsSending" v-if="!orderData.is_invoiced && (orderData.state === 1 || orderData.state === 3 || orderData.state === 5)" :label="$t('Cancel')" @click="confirmCancelOrder" icon="ti-close" class="p-button-warning p-button-raised p-mr-2"/>
              <Button v-else-if="orderData.state === 12 &&
                                !orderData.is_archived &&
                                ($store.state.user.role === constants.userRoles.superAdmin ||
                                 $store.state.user.role === constants.userRoles.admin)"
                      :disabled="disableEditButton || !allDataLoaded || dataIsSending"
                      :label="$t('Restore')"
                      @click="confirmReturnOrderToWork"
                      icon="ti-hummer"
                      class="p-button-secondary p-button-raised p-mr-2"/>
              <template v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin">
                <Button v-if="!orderData.is_archived" :disabled="disableEditButton || !allDataLoaded || orderData.state < 7 || dataIsSending" @click="confirmArchiveOrder" :label="$t('To archive')" icon="ti-archive" class="p-button-secondary p-button-raised"/>
                <Button v-else :disabled="disableEditButton || !allDataLoaded || dataIsSending"  @click="confirmReturnOrderFromArchive" :label="$t('Return from archive')" icon="ti-archive" class="p-button-info p-button-raised"/>
              </template>
            </div>
          </template>
        </Toolbar>
        <Spinner v-if="isLoading"></Spinner>
<!--        <div v-else-if="!isLoading">{{ orderData }}</div>-->

        <div v-else-if="!isLoading && orderData" class="p-grid" :class="{'datatable-is-loading':dataIsSending}">
<!--          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4" v-if="!noActivePositionInOrder || orderData.sales_invoice">-->
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4" v-if="(!noActivePositionInOrder || orderData.sales_invoice || orderData.task_order_number) && orderData.state !== 12">
            <fieldset class="p-fieldset">
              <legend class="p-fieldset-legend p-p-2">Documents</legend>
              <div v-if="orderData.sales_invoice" class="p-d-flex p-ai-center p-jc-between">
                <div>
                  <div class="p-mb-1">
<!--                    <div class="p-d-flex">-->
                   <div>{{ $t('Sales invoice') }} #: {{ orderData.sales_invoice.number }}
                     <i v-if="orderData.sales_invoice.changed_after_export" style="font-size: 1.2rem; position: relative; top: 2px" class="pi pi-exclamation-triangle warning-color p-ml-1" v-tooltip.top="'Changed after export'"></i>
                   </div>
<!--                      <div class="p-ml-2 p-d-flex p-ai-center green-color" v-if="orderData.sales_invoice.sent_at" v-tooltip.top="'Sent at: ' + formatDate(orderData.sales_invoice.sent_at)">-->
<!--                        <i style="font-size: 1.2em" class="ti-email"></i>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="p-pt-2" :class="{'warning-color': orderData.sales_invoice?.unpaid > 0}">Unpaid: {{ formatCurrency(orderData.sales_invoice?.unpaid) }} / {{ formatCurrency(orderData.sales_invoice?.grand_total) }}</div>
                    <div class="p-pt-2" v-if="orderData.sales_invoice.sent_at">
                      Sent at: {{ formatDate(orderData.sales_invoice.sent_at)}}<span v-if="orderData.sales_invoice.sent_by">, {{ orderData.sales_invoice.sent_by.first_name }} {{ orderData.sales_invoice.sent_by.last_name }}</span>
                    </div>
                    <div  class="p-d-flex p-flex-wrap p-ai-center p-pt-2">
                      <div class="p-mr-1">{{ $t('Status') }}:</div>
                      <div class="state-badge" :class="SalesInvoiceStates.find(state => state.num === orderData.sales_invoice.state)?.color || ''">{{ SalesInvoiceStates.find(state => state.num === orderData.sales_invoice.state)?.label[$i18n.locale] || '' }}</div>
                    </div>
                  <!--                <div v-if="(+orderData.sales_invoice?.unpaid != +orderData.sales_invoice?.grand_total) && orderData.sales_invoice?.unpaid != 0" class="warning-color p-mt-3">-->
                  <!--                  <i class="pi pi-exclamation-triangle p-mr-1" style="position: relative; top: 1px"></i>-->
                  <!--                  <span>Платежы не на всю сумму счета!</span>-->
                  <!--                </div>-->
                  </div>
                  <Button :disabled="disableEditButton || !allDataLoaded" @click="editSalesInvoice(orderData.sales_invoice)" class="p-button-text p-mt-1" icon="ti-pencil" style="width: auto" :label="$t('Edit')"></Button>
                </div>
<!--                  <div v-if="orderData.sales_invoice.file_path">-->
<!--                    <a @click.prevent="getPdf(orderData.sales_invoice.file_path)" href="#" class="p-link pdf-img-link">-->
<!--                      <img src="assets/images/pdf_md_icon.png" :alt="orderData.sales_invoice.number" />-->
<!--&lt;!&ndash;                      <div>{{ orderData.sales_invoice.number }}</div>&ndash;&gt;-->
<!--                    </a>-->
<!--                  </div>-->
                <GetSalesInvoicePdfButton :filePath="orderData.sales_invoice.file_path"/>
              </div>
<!--              <Button v-else-if="orderData.state !== 12 && !noActivePositionInOrder" @click="createSalesInvoice" class="p-button-text p-mt-1" icon="pi pi-plus" style="width: auto" :label="$t('Add')"></Button>-->
              <Button v-else-if="orderData.state !== 12 && !noActivePositionInOrder" @click="createSalesInvoice" class="p-button-text p-mt-1" icon="pi pi-plus" style="width: auto" :label="$t('Sales invoice')"></Button>

              <template v-if="orderData.task_order_number && orderData.task_order_path">
                <hr class="p-mt-2 p-mb-2" v-if="orderData.state !== 12 && !noActivePositionInOrder">
                <div  class="p-d-flex p-ai-center">
                  <div class="p-mr-2">Task order: {{ orderData.task_order_number }}</div>
                  <GetTaskOrderPdfButtonSM :filePath="orderData.task_order_path"/>
                </div>
              </template>
            </fieldset>
          </div>

<!--          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4" v-if="orderData.task_order_path || orderData.acceptance_act_path">-->
<!--            <fieldset class="p-fieldset">-->
<!--              <legend class="p-fieldset-legend p-p-2">Documents</legend>-->
<!--              <div v-if="orderData.task_order_number && orderData.task_order_path" class="p-d-flex p-ai-center">-->
<!--                <div class="p-mr-2">Task order: {{ orderData.task_order_number }}</div>-->
<!--                <GetTaskOrderPdfButtonSM :filePath="orderData.task_order_path"/>-->
<!--              </div>-->
<!--            </fieldset>-->
<!--          </div>-->

<!--          <OverlayPanel ref="op">-->
<!--            <img src="demo/images/nature/nature1.jpg" alt="Nature Image">-->
<!--          </OverlayPanel>-->
<!--          <Button @click="$refs.op.toggle(true)" label="AAAAAAAAA"></Button>-->
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4">
            <fieldset class="p-fieldset">
              <legend class="p-fieldset-legend p-p-2">Files</legend>
              <div v-if="orderData?.files">
                <div v-for="file of orderData.files" :key="file.id">
                  <div class="p-d-flex p-ai-center p-jc-between">
                    <div>
                      <a :href="settings.apiEndPoint + settings.orderFilesFolder + file.file_path" target="_blank">
<!--                        <div style="overflow: hidden; text-overflow: ellipsis;">-->
                          {{ file.source_name }}
                      </a>
                      <div class="p-mt-1" style="font-size: 0.9rem">
                        <span v-if="file.size">Size: {{ formatDecimal(file.size ? file.size / 1048576 : 0) }}mb</span>
                        <span v-if="file.created_at">, {{ $t('Created') }}: {{ formatDate(file.created_at) }}</span>
                        <span v-if="file.createdBy">,
                          <span v-if="file.createdBy.type === constants.userTypes.legal_entity && file.createdBy.company_name">{{ file.createdBy.company_name }}</span>
                          <span v-else>{{ file.createdBy.first_name }} {{ file.createdBy.last_name }}</span>
                        </span>
                      </div>
                    </div>
                    <Button @click="deleteFileOnClick(file)" :disabled="disableEditButton" icon="ti-trash" class="p-button-text p-button-danger"></Button>
                  </div>
                  <hr class="p-mt-2 p-mb-2">
                </div>
              </div>
              <Button @click="addFiles" :disabled="disableEditButton" class="p-button-text p-mt-1 p-mr-3" icon="pi pi-plus" style="width: auto" :label="$t('Add')"></Button>
              <Button :disabled="!userHaveEmailAddress || disableEditButton"
                      v-if="userEmailAccounts?.length && orderData.files?.length"
                      @click="createFilesEmail"
                      label="Send files"
                      icon="ti-email"
                      class="p-button-text"
                      style="width: auto;
                      height: auto;"/>
            </fieldset>
          </div>

<!--          <div class="p-col-12 p-md-6 p-lg-4 p-fluid contact-form" v-if="($store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin) && orderMasters.length && employeePayments.length">-->
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"
               v-if="showEmployeePaymentsFieldset">
<!--          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4" v-if="userIsAdminOrAccountant && orderMasters?.length && servicePositions?.length">-->
            <fieldset class="p-fieldset">
              <legend class="p-fieldset-legend p-p-2">Employee payments</legend>
              <span v-if="orderData?.employee_payments">
                <div v-for="payment of orderData.employee_payments.sort((a, b) => a.order_position_id - b.order_position_id)" :key="payment.id">
<!--                  <div v-if="payment.sum && +payment.sum">-->
                  <div>
                    <span v-if="payment.master">{{ payment.master.first_name }} {{ payment.master.last_name }}, </span>
                    <span>{{ payment.description }}: </span>
                    <span>{{ formatCurrency(payment.sum) }}</span>
                    <hr class="p-mt-2 p-mb-2">
                  </div>
                </div>
              </span>
              <div v-if="employeePayments?.length && servicePositions.length > employeePayments.length" class="warning-color p-mt-3 p-mb-2">
                <i class="pi pi-exclamation-triangle p-mr-1" style="position: relative; top: 1px"></i>
                <span>Произведены не все выплаты!</span>
              </div>
              <Button :disabled="disableEditButton || !salarySettings || !employeePayments || !servicePositions" v-if="!orderData.employee_payments?.length" @click="addEmpoloyeePayments" class="p-button-text p-mt-1" icon="pi pi-plus" style="width: auto" :label="$t('Add')"></Button>
              <Button :disabled="disableEditButton || !salarySettings || !employeePayments || !servicePositions" v-else @click="addEmpoloyeePayments" class="p-button-text p-mt-1" icon="ti-pencil" style="width: auto" :label="$t('Edit')"></Button>
            </fieldset>
          </div>

        </div>
        <div v-else class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>
			</div>
<!--      Second part-->
      <div v-if="!isLoading && orderData" class="card p-mt-4" :class="{'datatable-is-loading':dataIsSending}">
        <div class="p-grid p-mb-4">
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4">
            <Panel header="Order details">
              <table>
                <tbody style="text-align: left">
<!--                  <tr>-->
<!--                    <th class="p-p-1">Number:</th>-->
<!--                    <td class="p-p-1">-->
<!--                      <div class="p-ml-3 p-d-flex p-ai-center p-flex-wrap">-->
<!--                        <div class="p-mr-2">{{ $route.params.number || (orderData?.number) }}</div>-->
<!--                        <div v-if="orderData.parentOffer?.number" style="font-size: 0.85em;">-->
<!--                          <span class="p-mr-1">From offer:</span>-->
<!--                          <router-link :to="{ path: '/offers', query: { search: orderData.parentOffer.number } }" target="_blank">-->
<!--                            <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>-->
<!--                            <span>{{ orderData.parentOffer.number }}</span>-->
<!--                          </router-link>-->
<!--                        </div>-->
<!--                        <div v-else-if="orderData.parentOrder?.number" style="font-size: 0.85em;">-->
<!--                          <span class="p-mr-1">From order:</span>-->
<!--                          <router-link :to="{ path: `/orders/${orderData.parentOrder.number}` }" target="_blank">-->
<!--                            <i style="font-size: 0.90em" class="ti-hummer"></i>-->
<!--                            <span>{{ orderData.parentOrder.number }}</span>-->
<!--                          </router-link>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </td>-->
<!--                  </tr>-->
                  <tr>
                    <th class="p-p-1">Name:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3 p-d-flex p-ai-center p-flex-wrap">
                        <span v-if="orderData.name">{{ orderData.name }}</span>
                        <i v-else class="ti-minus"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">Time:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3">
                        <span v-if="orderData.time">{{ orderData.time }} {{ $t('h') }}.</span>
                        <i v-else class="ti-minus"></i>
                      </div>
<!--                      <span class="p-ml-3">{{ orderData.time }}</span>-->

                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">{{ $t('Status') }}:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3 p-d-flex p-ai-center">
                        <OrderStateButton :ref="'orderStateBtn-' + orderData.id"
                                          @change-order-state-to-waiting-on-click="changeOrderStateToWaitingOnClick"
                                          :item="orderData"
                                          @update-item="updateItem"
                                          :canChangeState="true"/>
                        <div v-if="orderData.state > 5 && orderData.state !== 12"
                             @click.prevent="toggleCalled"
                             class="p-link table-link-icon green-color"
                             :class="{'warning-color' : !orderData.called_at || !orderData.called_by}">
                          <i class="pi pi-phone"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="orderData.called_at || orderData.called_by">
                    <th class="p-p-1">Called:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3">
                      <span>
                        <span v-if="orderData.called_at">{{ formatDate(orderData.called_at) }}, </span>
                        <span v-if="orderData.calledBy">
<!--                          <span v-if="orderData.creator.type === constants.userTypes.legal_entity && orderData.creator.company_name">{{ orderData.creator.company_name }}<span> ({{ orderData.creator.first_name }} {{ orderData.creator.last_name }})</span></span>-->
                          <span>{{ orderData.calledBy.first_name }} {{ orderData.calledBy.last_name }}</span>
                        </span>
                      </span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="orderData.state === 12">
                    <th class="p-p-1">Cancellation reason:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3">
                        <span style="word-break: break-all" v-if="orderData.cancellation_reason">{{ orderData.cancellation_reason }}</span>
                        <i v-else class="ti-minus"></i>
                        <span>
                          <a @click.prevent="editCancellationReason" class="p-link p-p-1 p-ml-2">
                            <i class="ti-pencil"></i>
                          </a>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">{{ $t('Start') }}:</th>
                    <td class="p-p-1 p-d-flex p-flex-wrap">
                      <span class="p-ml-3">{{ formatDate(orderData.start) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">{{ $t('Car') }}:</th>
                    <td class="p-p-1">
                      <div v-if="orderData.car" class="p-d-flex p-ai-center">
                        <router-link :to="{ path: `/cars/${orderData.car_id}` }" target="_blank" class="p-ml-3">
                          <span><span v-if="orderData.car.make">{{ orderData.car.make.name }}</span> <span v-if="orderData.car.model">{{ orderData.car.model.name }}</span><span v-if="orderData.car.plate_number">, {{ orderData.car.plate_number }}</span></span>
                        </router-link>
                        <CarDataLink :plateNumber="orderData.car.plate_number" :showOnlyIcon="true"/>
                      </div>
                      <i v-else class="ti-minus p-ml-3"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>

          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4" v-if="orderData.customer">
            <Panel header="Customer details">
              <table>
                <tbody style="text-align: left">
                <tr>
                  <th class="p-p-1">{{ $t('Customer') }}:</th>
                  <td class="p-p-1">
                    <div class="p-ml-3">
                      <div class="p-d-flex p-ai-center p-flex-wrap">
                        <CustomerStatusHistoryButton
                            :customerStatus="orderData.customer.customerStatus"
                            :customerId="orderData.customer.id"
                            @change-customer-status="changeCustomerStatus"/>
                        <router-link :to="{ path: `/customers/${orderData.customer.id}` }" target="_blank">
                          <div class="p-mr-1" v-if="orderData.customer.type === constants.userTypes.legal_entity && orderData.customer.company_name">{{ orderData.customer.company_name }} ({{ orderData.customer.first_name }} {{ orderData.customer.last_name }})</div>
                          <div class="p-mr-1" v-else>{{ orderData.customer.first_name }} {{ orderData.customer.last_name }}</div>
                        </router-link>
                        <div class="customer-data-flag-wrapper">
                          <span :class="'flag flag-' + orderData.customer.flag_code"></span>
                        </div>
                      </div>
<!--                      <i v-else class="ti-minus"></i>-->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">{{ $t('Phone') }}:</th>
<!--                  <td class="p-p-1">-->
<!--                    <span class="p-ml-3 p-d-flex p-flex-wrap" v-if="orderData.customer?.phone_code">-->
<!--                      <span v-if="orderData.customer.type === constants.userTypes.legal_entity && orderData.customer.company_name"><span v-if="orderData.customer.company_phone_code">+</span>{{ orderData.customer.company_phone_code || '' }} {{ orderData.customer.company_phone_number || '' }}</span>-->
<!--                      <span v-else><span v-if="orderData.customer.phone_code">+</span>{{ orderData.customer.phone_code }} {{ orderData.customer.phone_number }}</span>-->
<!--                    </span>-->
<!--                    <i v-else class="ti-minus p-ml-3"></i>-->
<!--                  </td>-->

                  <td class="p-p-1">
                    <span class="p-ml-3">
                      <span v-if="orderData.customer.phone_code && orderData.customer.phone_number">+{{ orderData.customer.phone_code }} {{ orderData.customer.phone_number }}</span>
                      <span v-else-if="orderData.customer.type === constants.userTypes.legal_entity && orderData.customer.company_name && orderData.customer.company_phone_code && orderData.customer.company_phone_number">+{{ orderData.customer.company_phone_code }} {{ orderData.customer.company_phone_number }}</span>
                      <i v-else class="ti-minus"></i>
                    </span>
<!--                    <i v-else class="ti-minus p-ml-3"></i>-->
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">{{ $t('Email') }}:</th>
                  <td class="p-p-1">
                    <span v-if="orderData.customer" class="p-ml-3">
                      <span v-if="orderData.customer.email">{{ orderData.customer.email }}</span>
                      <span v-else-if="orderData.customer.company_email">{{ orderData.customer.company_email }}</span>
<!--                      <span v-else-if="orderData.customer.type === constants.userTypes.legal_entity && orderData.customer.company_name">{{ orderData.customer.company_email }}</span>-->
                      <i v-else class="ti-minus"></i>
                    </span>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">{{ $t('Created') }}:</th>
                  <td class="p-p-1">
                    <span class="p-ml-3" v-if="orderData.customer">{{ formatDate(orderData.customer.created_at) }}</span>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Counts:</th>
                  <td class="p-p-1">
                    <span v-if="orderData.customer" class="p-ml-3 p-d-flex p-flex-wrap">
                      <span class="p-mr-2">
                          <span class="ti-hummer"></span>
                          <span class="p-text-bold p-ml-1">{{ orderData.customer.orders_count ?? 0 }}</span>
                        </span>
                      <span class="p-mr-2">
                          <span class="ti-clipboard"></span>
                          <span class="p-text-bold p-ml-1">{{ orderData.customer.offers_count ?? 0 }}</span>
                        </span>
                      <span class="p-mr-2">
                          <span class="ti-car"></span>
                          <span class="p-text-bold p-ml-1">{{ orderData.customer.cars_count ?? 0 }}</span>
                        </span>
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </Panel>
          </div>
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4">
            <Panel header="Common data">
              <table>
                <tbody style="text-align: left">
                <tr>
                  <th class="p-p-1">{{ $t('Created') }}:</th>
                  <td class="p-p-1">
                    <div class="p-ml-3">
                      <span :class="{'order-created-by-customer':orderData.is_created_by_customer}">
                        <span v-if="orderData.created_at">{{ formatDate(orderData.created_at) }}, </span>
                        <span v-if="orderData.creator">
<!--                          <span v-if="orderData.creator.type === constants.userTypes.legal_entity && orderData.creator.company_name">{{ orderData.creator.company_name }}<span> ({{ orderData.creator.first_name }} {{ orderData.creator.last_name }})</span></span>-->
                          <span v-if="orderData.creator.type === constants.userTypes.legal_entity && orderData.creator.company_name">{{ orderData.creator.company_name }}</span>
                          <span v-else>{{ orderData.creator.first_name }} {{ orderData.creator.last_name }}</span>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Archived:</th>
                  <td class="p-p-1">
                    <div v-if="orderData.is_archived" class="p-ml-3">
                      <span v-if="orderData.archived_at">{{ formatDate(orderData.archived_at) }}, </span>
                      <span v-if="orderData.archivedBy">
<!--                        <span v-if="orderData.archivedBy.type === constants.userTypes.legal_entity && orderData.archivedBy.company_name">{{ orderData.archivedBy.company_name }}<span> ({{ orderData.archivedBy.first_name }} {{ orderData.archivedBy.last_name }})</span></span>-->
                        <span v-if="orderData.archivedBy.type === constants.userTypes.legal_entity && orderData.archivedBy.company_name">{{ orderData.archivedBy.company_name }}</span>
                        <span v-else>{{ orderData.archivedBy.first_name }} {{ orderData.archivedBy.last_name }}</span>
                      </span>
                      <span v-if="showEditArhivedAtButton">
                        <a @click.prevent="editArchivedAtDate" class="p-link p-p-1 p-ml-2">
                          <i class="ti-pencil"></i>
                        </a>
                      </span>
                    </div>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Responsible:</th>
                  <td class="p-p-1">
                    <span class="p-ml-3 p-d-flex p-flex-wrap" v-if="orderData.responsible">
<!--                      <router-link :to="{ path: `/employees/${orderData.responsible.id}` }" target="_blank">-->
                        {{ orderData.responsible.first_name }} {{ orderData.responsible.last_name }}
<!--                      </router-link>-->
                    </span>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Masters:</th>
                  <td class="p-p-1">
                    <span class="p-ml-3 p-d-flex p-flex-wrap" v-if="orderData.masters?.length">
                      <span v-for="(master, index) of orderData.masters" :key="master.id">
<!--                        <router-link :to="{ path: `/employees/${master.id}` }" target="_blank">-->
                        <span>{{ master.user.first_name }} {{ master.user.last_name }}</span>
                        <span v-if="orderData.masters.length > 1 && (index !== orderData.masters.length - 1)">,&nbsp;</span>
<!--                        </router-link>-->
                      </span>
                    </span>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Mileage:</th>
                  <td class="p-p-1">
                    <span class="p-ml-3" v-if="orderData.mileage">{{ formatMileage(orderData.mileage) }} {{ $t(settings.measurements.mileage) }}</span>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </Panel>
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mb-4" v-if="orderHasDeletedPositions">
          <InputSwitch id="show_deleted_checkbox" v-model="showDeletedPositions"/>
          <label for="show_deleted_checkbox" class="switch-label pointer">Show deleted</label>
        </div>
<!--        <div v-if="orderHasDeletedPositions">-->
<!--          <a @click.prevent="showDeletedPositions = !showDeletedPositions" class="p-link">-->
<!--            <i style="font-size: 0.9em" class="pi p-mr-1" :class="showDeletedPositions ? 'pi-minus' :'pi-plus'"></i>-->
<!--            <span>{{ showDeletedPositions ? 'Hide deleted' : 'Show deleted' }}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--        <div v-else class="p-mt-4"></div>-->
<!--        <div v-if="orderHasDeletedPositions">-->
<!--          <a @click.prevent="showDeletedPositions = !showDeletedPositions" class="p-link">-->
<!--            <i style="font-size: 0.9em" class="pi p-mr-1" :class="showDeletedPositions ? 'pi-minus' :'pi-plus'"></i>-->
<!--            <span>{{ showDeletedPositions ? 'Hide deleted' : 'Show deleted' }}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="p-mt-3">-->
<!--          <div v-if="deletedPositions">-->
<!--            <a v-if="showDeletedPositions" @click.prevent="showDeletedPositions = false" href="#" class="">Hide deleted</a>-->
<!--            <a v-else-if="!showDeletedPositions" @click.prevent="showDeletedPositions = true" href="#" class="">Show deleted</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <PositionsTable :item="orderData" :showDeletedPositions="showDeletedPositions" :isSpoilerTable="false" @update-items="updateItems"/>-->
        <PositionsTable :item="orderData" :showDeletedPositions="showDeletedPositions" :isSpoilerTable="false" @update-items="updateItems"/>
      </div>

      <SalesInvoiceModal :visible="salesInvoiceModal"
                         :item="salesInvoiceData"
                         :masters="masters"
                         :responsibles="responsibles"
                         :taxes="taxes"
                         :services="services"
                         :suppliers="suppliers"
                         :userEmailAccounts="userEmailAccounts"
                         @update-order="updateItem"
                         @close="closeSalesInvoiceModal">
      </SalesInvoiceModal>

      <OrderModal :visible="orderModal"
                  :item="order"
                  :masters="masters"
                  :responsibles="responsibles"
                  :taxes="taxes"
                  :services="services"
                  :suppliers="suppliers"
                  :areas="areas"
                  :warehouseItems="warehouseItems"
                  @change-is-warning="changeIsWarning"
                  @updatePosition="updatePosition"
                  @update-item="updateItem"
                  @close="closeOrderModal">
      </OrderModal>

      <EmployeePaymentsModal :visible="empoloyeePaymentsModal"
                             :employeePayments="employeePayments"
                             :servicePositions="servicePositions"
                             :orderMasters="orderMasters"
                             :salarySettings="salarySettings"
                             :orderId="orderData?.id"
                             @update-item="updateItem"
                             @close="closeEmpoloyeePaymentsModal">
      </EmployeePaymentsModal>

      <ConfirmModal :visible="confirmArchiveOrderModal"
                    :showSpinner="dataIsSending"
                    @confirm="archiveOrder"
                    @close="closeArchiveOrderModal"/>

      <ConfirmModal :visible="confirmReturnFromArchiveModal"
                    :showSpinner="dataIsSending"
                    @confirm="returnOrderFromArchive"
                    @close="closeReturnFromArchiveModal"/>

      <ConfirmModal :visible="confirmReturnOrderToWorkModal"
                    :showSpinner="dataIsSending"
                    @confirm="returnOrderToWork"
                    @close="closeConfirmReturnOrderToWorkModal"/>

      <ConfirmDeleteModal :visible="confirmDeleteFileModal"
                          :modalDataIsLoading="dataIsSending"
                          :data="fileToDelete?.source_name"
                          @delete-item="deleteFile"
                          @close="closeConfirmDeleteFileModal"/>

      <OrderCancellationModal :visible="confirmCancelOrderModal"
                              :order="order"
                              :activeOrderedRequestPositions="activeOrderedRequestPositions"
                              @update-item="updateItem"
                              @close="closeConfirmCancelOrderModal"/>

      <ArchivedAtDateModal :visible="archivedAtDateModal"
                            @update-item="updateItem"
                            :archivedAt="orderData?.archived_at"
                            :orderId="orderData?.id"
                            @close="closeArchivedAtDateModal"/>

      <OfferModal :visible="offerModal"
                  :isSecondModal="true"
                  :item="offer"
                  :masters="masters"
                  :responsibles="responsibles"
                  :taxes="taxes"
                  :services="services"
                  :suppliers="suppliers"
                  :warehouseItems="warehouseItems"
                  @update-item="updateItem"
                  @close="closeOfferModal">
      </OfferModal>

      <FilesModal :visible="filesModal"
                  @close="closeFilesModal"
                  :orderId="orderData?.id" @update-item="updateItem"/>

      <EmailModal :visible="emailModal"
                  :userEmailAccounts="userEmailAccounts"
                  :item="emailData"
                  @update-items="updateItems"
                  @close="closeEmailModal">
      </EmailModal>

      <ChangeOrderToWaitingModal :visible="changeOrderToWaitingModal"
                                 :orderData="orderToWaitingData"
                                 :showSpinner="dataIsSending"
                                 @change-state="changeOrderStateToWaiting"
                                 @close="closeChangeOrderToWaitingModal"/>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import RequestPositionStates from '@/translations/states/RequestPositionStates'
import settings from '@/settings.js'
import SalesInvoiceModal from '@/pages/finance/components/SalesInvoiceModal'
import OrderModal from '@/pages/orders/components/OrderModal'
import constants from '@/constants'
import EmployeePaymentsModal from '@/pages/orders/components/EmployeePaymentsModal'
import ConfirmModal from '@/components/ConfirmModal'
import OrderStateButton from '@/pages/orders/components/OrderStateButton'
import formatMixins from '@/mixins/formatMixins'
import PositionsTable from '@/components/PositionsTable'
import httpMixins from "@/mixins/httpMixins";
// import GetPdfButton from "@/pages/finance/components/GetPdfButton";
import GetSalesInvoicePdfButton from "@/pages/finance/components/GetSalesInvoicePdfButton";
import OrderCancellationModal from "@/pages/orders/components/OrderCancellationModal";
import datatableMixins from "@/mixins/datatableMixins";
import SmsMessages from "@/translations/SmsMessages";
import AddNewButton from "@/components/DataTable/AddNewButton";
import GetTaskOrderPdfButtonSM from "@/pages/orders/components/GetTaskOrderPdfButtonSM";
import OfferModal from "@/pages/offers/components/OfferModal";
import ArchivedAtDateModal from "@/pages/orders/components/ArchivedAtDateModal";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import FilesModal from "@/pages/orders/components/FilesModal";
import EmailModal from "@/pages/messages/components/EmailModal";
import webSocketMixins from "@/mixins/webSocketMixins";
import SalesInvoiceStates from "@/translations/states/SalesInvoiceStates";
import permissionsMixins from "@/mixins/permissionsMixins";
import ChangeOrderToWaitingModal from "@/pages/orders/components/ChangeOrderToWaitingModal";
import CarDataLink from "@/pages/cars/components/CarDataLink";

export default {
  components: { SalesInvoiceModal,OrderModal,EmployeePaymentsModal,ConfirmModal,OrderStateButton,PositionsTable,GetSalesInvoicePdfButton,OrderCancellationModal,AddNewButton,GetTaskOrderPdfButtonSM, OfferModal,ArchivedAtDateModal,CustomerStatusHistoryButton,FilesModal,EmailModal,ChangeOrderToWaitingModal,CarDataLink },
	mixins: [ formatMixins, httpMixins, datatableMixins, webSocketMixins, permissionsMixins ],
  data() {
		return {
      dataIsSending: false,
      emailModal: false,
      emailData: {},
      fileToDelete: {},
      confirmDeleteFileModal: false,
      filesModal: false,
      archivedAtDateModal: false,
      offerModal: false,
      offer: {},
      orderStartDate: null,
      remindAppointment: true,
      // remindAppointmentWasActive: true,
      sendNotificationAt: null,
      notificationSmsText: null,
      startDateIsValid: true,
      // cancellationReason: null,
      userEmailAccounts: null,
      confirmReturnOrderToWorkModal: false,
      confirmCancelOrderModal: false,
      disableEditButton: false,
      orderMasters: [],
      salarySettings: null,
      warehouseItems: null,
      masters: null,
      responsibles: null,
      taxes: null,
      services: null,
      suppliers: null,
      areas: null,
      salesInvoiceData: {},
      salesInvoiceModal: false,
      empoloyeePaymentsModal: false,
      confirmArchiveOrderModal: false,
      confirmReturnFromArchiveModal: false,
      employeePayments: null,
      servicePositions: null,
      order: {},
      orderData: null,
      orderModal: false,
      isLoading: true,
      // orderHasDeletedPositions: false,
      // deletedPositions: false,
      showDeletedPositions: false,
      settings,
      // RequestPositionStates: RequestPositionStates[this.$i18n.locale],
      RequestPositionStates,
      constants,
      SalesInvoiceStates
		}
	},
  watch: {
    '$route'() {
      if (this.$route.path === '/login' || this.$route.path !== '/orders/' + this.$route.params.number) {
        return false
      }
      if (this.$route.params.number) {
        this.getOrderData()
      }
    },
    // '$store.state.updatedOrderId'(id) {
    //   if (id && id === this.orderData?.id) {
    //     this.getOrderData()
    //   }
    // },
    // '$i18n.locale'() {
    //   this.RequestPositionStates = RequestPositionStates[this.$i18n.locale]
    // },
    // employeePayments(value) {
    //   console.log(value)
    // },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.salesInvoiceModal) {
          this.closeSalesInvoiceModal()
        }
        if (this.orderModal) {
          this.closeOrderModal()
        }
        if (this.empoloyeePaymentsModal) {
          this.closeEmpoloyeePaymentsModal()
        }
        if (this.confirmArchiveOrderModal) {
          this.closeArchiveOrderModal()
        }
        if (this.confirmReturnFromArchiveModal) {
          this.closeReturnFromArchiveModal()
        }
        if (this.confirmCancelOrderModal) {
          this.closeConfirmCancelOrderModal()
        }
        if (this.confirmReturnOrderToWorkModal) {
          this.closeConfirmReturnOrderToWorkModal()
        }
        if (this.offerModal) {
          this.closeOfferModal()
        }
        if (this.archivedAtDateModal) {
          this.closeArchivedAtDateModal()
        }
        if (this.filesModal) {
          this.closeFilesModal()
        }
        if (this.confirmDeleteFileModal) {
          this.closeConfirmDeleteFileModal()
        }
        if (this.emailModal) {
          this.closeEmailModal()
        }
        if (this.changeOrderToWaitingModal) {
          this.closeChangeOrderToWaitingModal()
        }
      }
    },
    '$store.state.user.emailAccounts'() {
      this.generateUserEmailAccounts()
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    // if (!this.$store.state.user?.role || !this.$route.params.number) return false
    if (!this.$route.params.number) return false

    this.getOrderData()
    this.getModalData()
    // this.getMasters()
    // this.getResponsibles()
    // this.getTaxes()
    // this.getServices()
    // this.getSuppliers()
    // this.getUserCashboxes()
    // this.getAreas()
    // this.getWarehouseItems()

    this.generateUserEmailAccounts()
	},
  methods: {
    changeIsWarning(id, isWarning) {
      if (this.orderData.id !== id) return false
      // const item = this.items.find(item => item.id === id)
      // if (!item) return false
      this.orderData.is_warning = isWarning
    },
    createFilesEmail() {
      const customer = this.orderData?.customer
      if (!customer) return false

      // const salesInvoiceMessage = EmailMessages.salesInvoice[salesInvoice.customer?.language] ?? EmailMessages.salesInvoice[settings.defaultLanguage]
      this.emailData = {
        fromOrderFilesPage: true,
        // sales_invoice_id: salesInvoice.id,
        subject: `Tellimus #${this.orderData.number} failid`,
        // sales_invoice_text: salesInvoiceMessage?.text,
        // sent_at: salesInvoice.sent_at,
        // file_path: salesInvoice.file_path,
        // user_id: salesInvoice.user_id,
        attachments: this.orderData.files,
        to_email: customer.type === 2 && customer.company_name ? (customer.invoice_email ?? customer.company_email) : customer.email
        // offer_link_text: settings.useOfferRegistrationLink && offerMessage ? offerMessage.linkText  : null,
      }

      this.emailModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeEmailModal() {
      this.emailData = {}
      this.emailModal = false
      // this.submitted = false
      this.$store.commit('toggleFirstLayer', false)
    },
    userHaveEmailAddress() {
      const user = this.orderData?.customer
      if (!user) return false
      if (user.type === this.constants.userTypes.legal_entity && user.company_name) {
        return !!(user.invoice_email || user.company_email)
      } else {
        return !!user.email
      }
    },
    async deleteFile() {
      this.dataIsSending = true
      const fileId = this.fileToDelete?.id
      try {
        const { status } = await httpClient.post(`order/delete-file`, { fileId })
        if (status === 204) {
          this.closeConfirmDeleteFileModal()
          // this.orderData.files = this.orderData.files?.filter(f => f.id !== fileId)
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife});
          this.updateItem(this.orderData?.id, true)
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }
    },
    deleteFileOnClick(file) {
      this.fileToDelete = file
      this.confirmDeleteFileModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeConfirmDeleteFileModal() {
      this.confirmDeleteFileModal = false
      this.$store.commit('toggleFirstLayer', false)
      this.fileToDelete = {}
    },
    addFiles() {
      this.filesModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeFilesModal() {
      this.filesModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    updatePosition(positionData) {
      if (!positionData || !positionData.id) return false
      const position = this.orderData.positions.find(p => p.id === positionData.id)
      if (position) {
        position.request_state_history = positionData.request_state_history
        position.state = positionData.state
      }
    },
    changeCustomerStatus(newStatus) {
      if (this.orderData?.customer) {
        if (this.orderData.customer.customerStatus) {
          this.orderData.customer.customerStatus.status_id = newStatus
        } else {
          this.orderData.customer.customerStatus = {}
          this.orderData.customer.customerStatus.status_id = newStatus
        }
      }
    },
    // changeUpdatedItemId(id) {
    //   if (id && id === this.orderData?.id) {
    //     this.getOrderData()
    //   }
    // },
    async toggleCalled() {
      // const item = this.items.find(item => item.id === itemId)
      // item.is_warning = !item.is_warning
      if (!this.orderData) return false
      try {
        const { status, data } = await httpClient.post('order/toggle-called', { id : this.orderData.id })
        if (status === 200 && data?.success) {
          if (data.called_at) {
            this.orderData.called_at = data.called_at
            this.orderData.calledBy = {
              first_name: this.$store.state.user.first_name,
              last_name: this.$store.state.user.last_name,
            }
            this.orderData.called_by = this.$store.state.user.id
          } else {
            this.orderData.called_at = null
            this.orderData.calledBy = null
            this.orderData.called_by = null
          }
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        // item.is_warning = !item.is_warning
        this.showError(err)
      }
    },
    addNewOffer() {
      this.offer = {
        isNew: true,
        ...this.orderData,
        // start: +new Date(new Date() / 1000),
        positions: this.orderData.positions.filter(pos => pos.status === 1)?.map(p => ({ ...p, isNew: true })),
        id: null,
        responsible: null,
        state: 3,
        comments_history: null,
        history: null,
        customerDisabled: false,
        carDisabled: false,
        // selectedMasters: [],
        orderId: this.orderData.id,
      }

      // if (this.orderModal) {
      //   this.orderModal = false
      // }
      this.offerModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeOfferModal() {
      this.offerModal = false
      if (this.$store.state.firstLayerIsOpened) {
        this.$store.commit('toggleFirstLayer', false)
      }
      // this.item = {}
      this.submitted = false
    },
    dublicateOrder() {
      this.order = {
        isNew: true,
        ...this.orderData,
        start: +new Date(new Date() / 1000),
        positions: this.orderData.positions.filter(pos => pos.status === 1)?.map(p => ({ ...p, isNew: true })),
        id: null,
        responsible: null,
        state: 1,
        comments_history: null,
        history: null,
        customerDisabled: false,
        carDisabled: false,
        area_id: null,
        // selectedMasters: [],
        orderId: this.orderData.id,
        mileage: null,
      }

      // if (this.orderModal) {
      //   this.orderModal = false
      // }
      this.orderModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    checkStartDateIsValid() {
      const startDate = this.orderStartDate
      if (!startDate) return false
      this.startDateIsValid = startDate instanceof Date && !isNaN(startDate)
    },
    calculateRemindAppointmentState() {
      const orderStartDate = this.orderStartDate

      if (!orderStartDate || !this.startDateIsValid) {
        return false
      }

      const now = new Date()
      const nowYear = now.getFullYear()
      const nowMonth = now.getMonth()
      const nowDay = now.getDate()
      // const nowHours = now.getHours()
      // const nowMinutes = now.getMinutes()

      // const orderStartDate = startDate
      const orderStartYear = orderStartDate.getFullYear()
      const orderStartMonth = orderStartDate.getMonth()
      const orderStartDay = orderStartDate.getDate()
      // const orderStartHours = orderStartDate.getHours()
      // const orderStartMinutes = orderStartDate.getMinutes()

      if (orderStartYear < nowYear ||
          orderStartYear === nowYear && orderStartMonth < nowMonth ||
          orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay <= nowDay) {
        // orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay === nowDay && orderStartHours <= nowHours ||
        // orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay === nowDay && orderStartHours === nowHours && orderStartMinutes <= nowMinutes) {

        this.remindAppointment = false
        this.disableRemindAppointment = true
        this.sendNotificationAt = null
      } else {
        this.remindAppointment = true
        this.disableRemindAppointment = false
        this.sendNotificationAt = orderStartDay === nowDay + 1 ? new Date() : new Date(orderStartYear, orderStartMonth, orderStartDay - 1, 10, 30)
      }
    },
    generateSmsNotificationText() {
      const orderStartDate = this.orderStartDate
      if (!orderStartDate) return false

      const orderStartYear = orderStartDate.getFullYear()
      let orderStartMonth = orderStartDate.getMonth()
      let orderStartDay = orderStartDate.getDate()

      const notification_sms_text = SmsMessages.appointmentReminder[this.selectedCustomer?.language]?.text ?? SmsMessages.appointmentReminder[settings.defaultLanguage]?.text
      if (notification_sms_text) {
        if (orderStartDay?.toString().length === 1) {
          orderStartDay = '0' + '' + orderStartDay
        }
        if (orderStartMonth?.toString().length === 1) {
          orderStartMonth = '0' + '' + (orderStartMonth + 1)
        }

        let hours = orderStartDate.getHours()
        let minutes = orderStartDate.getMinutes()

        if (hours?.toString().length === 1) {
          hours = '0' + '' + hours
        }
        if (minutes?.toString().length === 1) {
          minutes = '0' + '' + minutes
        }

        const searchDate = '{date}'
        const searchTime = '{time}'
        const replaceDateWith = `${orderStartDay}.${orderStartMonth}.${orderStartYear}`
        const replaceTimeWith = `${hours}:${minutes}`

        let result = notification_sms_text.split(searchDate).join(replaceDateWith).split(searchTime).join(replaceTimeWith)

        this.notificationSmsText = result
        // console.log(result)
      }
    },
    // async sendSms() {
    //   try {
    //     const { status, data } = await httpClient.post('sms/send', { smsText: this.smsText, branchId: this.$store.state.branchData.id, customerId: 1 })
    //     if (status === 200 && data) {
    //       const smsApiSettings = data
    //       console.log(smsApiSettings)
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   }
    // },
    // generateUserEmailAccounts() {
    //   if (!this.$store.state.user.emailAccounts?.length) return false
    //   this.userEmailAccounts = this.$store.state.user.emailAccounts.map(account => (
    //       {id: account.accountData?.id, username: account.accountData?.username, sender_name: account.accountData?.sender_name, signature: account.signature}
    //   ))
    // },
    // async cancelOrder() {
    //   try {
    //     console.log('try cancel order')
    //   } catch(err) {
    //     this.showError(err)
    //   }
    // },
    editArchivedAtDate() {
      this.archivedAtDateModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeArchivedAtDateModal() {
      // this.cancellationReason = reason ?? null
      this.archivedAtDateModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    editCancellationReason() {
      this.order = { id: this.orderData.id, cancellation_reason: this.orderData.cancellation_reason ?? null }
      this.confirmCancelOrderModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    async returnOrderToWork() {
      this.dataIsSending = true
      if (!this.orderData.id) return false

      this.calculateRemindAppointmentState()

      const orderData = {
        id: this.orderData.id,
        remind_appointment: this.remindAppointment
      }

      // if (this.orderData.remind_appointment && this.remindAppointment && this.sendNotificationAt && this.notificationSmsText) {
      //   orderData.remind_appointment = true
      //   orderData.send_notification_at = +new Date(this.sendNotificationAt / 1000)
      //   orderData.notification_sms_text = this.notificationSmsText
      // }

      if (this.orderData.remind_appointment) {
        this.generateSmsNotificationText()
        if (this.notificationSmsText && this.sendNotificationAt) {
          orderData.send_notification_at = +new Date(this.sendNotificationAt / 1000)
          orderData.notification_sms_text = this.notificationSmsText
        }
      }

      try {
        const { status, data } = await httpClient.post('order/return-to-work', orderData)
        if (status === 200 && data?.success) {
          this.closeConfirmReturnOrderToWorkModal()
          // this.getOrderData(false)
          this.updateItem(this.orderData.id, true)
        } else {
          this.showUnknownError()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }
    },
    confirmReturnOrderToWork() {
      this.confirmReturnOrderToWorkModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeConfirmReturnOrderToWorkModal() {
      this.confirmReturnOrderToWorkModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    confirmCancelOrder() {
      this.order = { ...this.orderData }
      this.confirmCancelOrderModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    confirmArchiveOrder() {
      this.confirmArchiveOrderModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    confirmReturnOrderFromArchive() {
      this.confirmReturnFromArchiveModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeArchiveOrderModal() {
      this.confirmArchiveOrderModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    closeReturnFromArchiveModal() {
      this.confirmReturnFromArchiveModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    closeConfirmCancelOrderModal() {
      this.confirmCancelOrderModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    async archiveOrder() {
      this.dataIsSending = true
      if (this.orderData) {
        const obj = {
          orderId: this.orderData.id
        }
        // if (this.orderData.positions?.length) {
        //   obj.warehousePositions = this.orderData.positions.filter(position => position.type === 3 && position.status === 1)
        // }
        try {
          const { status, data } = await httpClient.post('order/archive-order', obj )
          if (status === 200 && data?.success) {
            this.orderData.is_archived = 1
            this.orderData.archived_at = +new Date() / 1000
            this.orderData.archivedBy = {
              first_name: this.$store.state.user.first_name,
              last_name: this.$store.state.user.last_name,
            }
            // this.getOrderData(false)
            this.closeArchiveOrderModal()
            // this.sendArchiveOrderWebSocket(this.orderData.id)
          } else {
            this.showUnknownError()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
        }
      }
    },
    async returnOrderFromArchive() {
      this.dataIsSending = true
      if (!this.orderData) return false

      this.calculateRemindAppointmentState()

      const orderData = {
        orderId: this.orderData.id,
        remind_appointment: this.remindAppointment
      }

      // if (this.orderData.remind_appointment && this.remindAppointment && this.sendNotificationAt && this.notificationSmsText) {
      //   orderData.remind_appointment = true
      //   orderData.send_notification_at = +new Date(this.sendNotificationAt / 1000)
      //   orderData.notification_sms_text = this.notificationSmsText
      // }

      if (this.orderData.remind_appointment) {
        this.generateSmsNotificationText()
        if (this.notificationSmsText && this.sendNotificationAt) {
          orderData.send_notification_at = +new Date(this.sendNotificationAt / 1000)
          orderData.notification_sms_text = this.notificationSmsText
        }
      }
      //
      // const orderData = {
      //   orderId: this.orderData.id
      // }
      // if (this.orderData.positions?.length) {
      //   obj.warehousePositions = this.orderData.positions.filter(position => position.type === 3 && position.status === 1)
      // }

      try {
        const { status, data } = await httpClient.post('order/return-from-archive', orderData )
        if (status === 200 && data?.success) {
          this.orderData.is_archived = 0
          // this.getOrderData(false)
          this.closeReturnFromArchiveModal()
        } else {
          this.showUnknownError()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }

    },
    editOrder() {
      this.order = { ...this.orderData }
      this.orderModal = true
      // this.$store.commit('toggleFirstLayer', true)
      this.$store.commit('openRequiredAppLayer')
    },
    // async getUserCashboxes() {
    //   try {
    //     const { status, data } = await httpClient('cashbox/get-user-cashboxes')
    //     if (status === 200) {
    //       const cashboxes = data.map(cashbox => cashbox.cashbox)
    //       this.$store.commit('updateUserCashboxes', cashboxes)
    //     }
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    updateItem(id, sendSocket = false) {
      const orderDataId = this.orderData?.id
      if (!orderDataId || id !== orderDataId) return

      this.disableEditButton = true
      if (this.$route.params.number) {
        this.getOrderData(false)
        if (sendSocket) {
          this.sendUpdateOrderWebSocket(orderDataId)
        }
      }
    },
    addEmpoloyeePayments() {
      this.empoloyeePaymentsModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeEmpoloyeePaymentsModal() {
      this.empoloyeePaymentsModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    createSalesInvoice() {
      this.salesInvoiceData = {
        isNew: true,
        order_id: this.orderData.id ?? null,
        selectedCustomer: this.orderData.selectedCustomer,
        selectedCar: this.orderData.selectedCar,
        positions: this.orderData.positions.map(p => ({ ...p, isNew: true })),
        recommendation: this.orderData.recommendation ?? null,
        use_consumables: this.orderData.use_consumables ? this.orderData.use_consumables : false,
        consumable: this.orderData.consumable ? this.orderData.consumable : null,
        order: {
          name: this.orderData.name ? this.orderData.name : ''
        },
        // summary:
        sum: this.orderData.sum,
        tax: this.orderData.tax,
        adjustment: this.orderData.adjustment,
        grand_total: this.orderData.grand_total
      }

      this.salesInvoiceModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    editSalesInvoice(data) {
      if (!this.orderData) return false

      this.salesInvoiceData = {
        order_id: this.orderData.id,
        selectedCustomer: this.orderData.selectedCustomer,
        user_id: this.orderData.user_id,
        selectedCar: this.orderData.selectedCar,
        positions: this.orderData.positions?.length ? this.orderData.positions : null,
        recommendation: data.recommendation,
        use_consumables: this.orderData.use_consumables,
        consumable: this.orderData.consumable,
        order: {
          name: this.orderData.name,
          is_archived: this.orderData.is_archived
        },
        id: data.id ? data.id : null,
        number: data.number ? data.number : null,
        issued: data.issued ? +data.issued : null,
        payment_due: data.payment_due ? +data.payment_due : null,
        penalty: data.penalty ? +data.penalty : null,
        state: data.state ? data.state : null,
        invoice_comment: data.invoice_comment ? data.invoice_comment : null,
        staff_comment: data.staff_comment ? data.staff_comment : null,
        show_car_data: data.show_car_data,
        payments: data.payments ?? null,
        file_path: data.file_path ?? null,
        changed_after_export: data.changed_after_export,
        export_id: data.export_id,
        export_number: data.export_number,
        sent_at: data.sent_at,
        sum: this.orderData.sum,
        tax: this.orderData.tax,
        adjustment: this.orderData.adjustment,
        grand_total: this.orderData.grand_total
      }

      this.salesInvoiceModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    toggleRecommendationHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['rh' + id.toString()].toggle(event);
    },
    toggleStaffCommentsHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['sh' + id.toString()].toggle(event);
    },
    toggleCustomerCommentsHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['ch' + id.toString()].toggle(event);
    },
    async getOrderData(showSpinner = true) {
      this.isLoading = showSpinner
      this.dataIsSending = true
      this.disableEditButton = true
      try {
        const { data, status } = await httpClient.post('order/get-order-by-number?expand=responsible,customer,customer.customerStatus,customer.address,creator,archivedBy,masters,masters.employee,employee_payments,employee_payments.master,positions.history,positions.request_state_history,consumable,car,area,state_history,comments_history,recommendation_history,sales_invoice,sales_invoice.payments,sales_invoice.sent_by,parentOffer,parentOrder,childOrder,childOffer,calledBy,files.createdBy,sales_invoice_issued_date', { orderNumber: this.$route.params.number })
        if (status === 200 && data && data.success && data.order) {
          const order = data.order
          this.orderData = order
          this.orderData.selectedCustomer = order.customer ?? null
          this.orderData.selectedCar = order.car ?? null

          if (order.start) {
            this.orderStartDate = new Date(order.start * 1000)
            this.checkStartDateIsValid()
          }

          // if (order.positions?.length) {
          //   this.orderHasDeletedPositions = order.positions.find(position => position.status === 0)
            // const deletedPositions = order.positions.filter(position => position.status === 0)
            // if (deletedPositions?.length) {
            //   this.deletedPositions = true
            // }
          // }

          if (order.employee_payments?.length) {
            this.employeePayments = order.employee_payments
          } else {
            this.employeePayments = []
          }

          if (order.masters && order.masters.length) {
            this.orderMasters = order.masters
            this.salarySettings = order.masters.map(master => {
              return {
                userId: master.user?.id,
                salary_settings: master.employee?.salary_settings
              }
            })
          } else {
            this.orderMasters = []
            this.salarySettings = []
          }

          const services = order.positions?.filter(position => position.type === 1 && position.status === 1 && position.master)
          if (services) {
            this.servicePositions = services.map(service => {
              return {
                ...service,
                isNew: true
              }
            })
          } else {
            this.servicePositions = []
          }
        } else {
          this.orderData = null
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.$store.commit('changeUpdatedOrderId', null)
        this.isLoading = false
        this.dataIsSending = false
        this.disableEditButton = false
      }
    },
    closeSalesInvoiceModal() {
      this.salesInvoiceModal = false
      if (this.$store.state.firstLayerIsOpened === true ) {
        this.$store.commit('toggleFirstLayer', false)
      }
      this.salesInvoiceData = {}
      this.submitted = false
    },
    closeOrderModal() {
      this.orderModal = false
      this.$store.commit('toggleFirstLayer', false)
      this.submitted = false
    },
	},
  computed: {
    orderHasDeletedPositions() {
      // this.orderHasDeletedPositions = order.positions.find(position => position.status === 0)
        return this.orderData?.positions?.find(p => p.status === 0)
    },
    // orderHasDeletedPositions() {
    //   // return true
    //   // console.log(!!this.orderData?.positions?.find(p => p.status = 0))
    //   return !!this.orderData?.positions?.find(p => p.status = 0)
    // },
    showEmployeePaymentsFieldset() {
      return ((this.userIsAdminOrAccountant && this.orderData.state !== 12 && this.orderMasters?.length && this.servicePositions?.length) ||
              (this.userIsAdminOrAccountant && this.orderData.employee_payments?.length))
    },
    showEditArhivedAtButton() {
      // return !!(this.$store.state.user.role === constants.userRoles.superAdmin ||
      //     this.$store.state.user.role === constants.userRoles.admin ||
      //     this.$store.state.user.role === constants.userRoles.accountant)
      return this.userIsAdminOrAccountant
    },
    showEditOrderButton() {
      // return !!((this.orderData.state !== 12 && !this.orderData.archived_at) ||
      //     this.$store.state.user.role === constants.userRoles.superAdmin ||
      //     this.$store.state.user.role === constants.userRoles.admin ||
      //     this.$store.state.user.role === constants.userRoles.accountant)
      return !!((this.orderData.state !== 12 && !this.orderData.archived_at) || this.userIsAdminOrAccountant)
    },
    noActivePositionInOrder() {
      // return !(this.orderData?.positions.filter(p => p.status === 1)?.length)
      return !(this.orderData?.positions?.find(p => p.status === 1))
    },
    allDataLoaded() {
      return !!(this.masters && this.responsibles && this.taxes && this.services && this.suppliers && this.areas && this.warehouseItems)
    },
    itemToDeleteData() {
      return 'delete data'
    },
    activeOrderedRequestPositions() {
      return this.orderData?.positions?.filter(pos => pos.status && pos.type === 2 && pos.state >= 4)
    }
    // thereIsActiveOrderedRequestPositions() {
    //   return !!this.orderData?.positions?.find(pos => pos.status && pos.type === 2 && pos.state >= 4)
    // }
  }
}
</script>
<style lang="scss" scoped>

</style>